@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');

html,
body,
label {
  font-family: 'Roboto', sans-serif;
  z-index: 0;

}

/* body {
  background-image: url(./assets/pin.png);
  background-size: 10% 10%;
  background-repeat: no-repeat;
  background-position: right;
  background-position: bottom right;

} */

.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}


/* @page {
  size: A4;
} */


@media print {

  /* ... the rest of the rules ... */
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}


@layer utilities {
  .container-w-h {
    min-height: 90%;
    max-height: 90%;
  }

  .custom-circle {
    @apply bg-blue-900;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .logo-heading {
    @apply text-sm md:text-xl text-white font-bold;
  }

  .page-heading {
    background-color: rgb(173 182 18);
  }

  .nav-bar-bg {
    background: rgb(31 41 55);
  }

  .nav-item {
    @apply text-white text-sm;
  }

  .logo-footer {
    background-image: url(./assets/pin.png);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: right;
    background-position: bottom right;
  }

  .nav-dropdown-bg {
    @apply bg-white hover:bg-gray-200 text-xs text-black;
  }

  .bg-theme {
    @apply bg-gray-50;
  }

  .heading {
    @apply text-lg text-white font-bold;
  }

  .sub-heading {
    @apply text-xs text-blue-800 font-bold;
    font-size: 16px
  }


  .frame {
    @apply border-2;
    border-radius: 10px;

  }

  .form {
    width: 50%;
  }

  .data {
    @apply text-xs;
  }

  .stick-bg {
    @apply bg-gray-600 text-white;
  }

  .input-field {
    @apply text-black h-6 w-5/6;
  }

  .table-header {
    @apply text-black text-sm bg-gray-900 border-2;

  }

  .table-row {
    @apply odd:bg-gray-50 even:bg-gray-200 text-black text-xs;
    font-size: 12px;

  }

  .table-data-input {
    @apply bg-transparent focus:bg-yellow-200;
  }

  .table-data {
    @apply border-x border-gray-400 cursor-pointer;
    padding: 3px;
  }

  .sub-navbar {
    @apply border rounded text-blue-900 font-bold p-1 text-sm
  }

  .sub-navbar-active {
    @apply bg-blue-900 text-white rounded p-1 text-sm
  }

  .button-border {
    @apply border rounded border-gray-400;
  }

  .pushable {
    border-radius: 12px;
    cursor: pointer;
  }

  .front {
    border-radius: 12px;
    transform: translateY(-6px);
  }

  .pushable:active .front {
    transform: translateY(-2px);
  }
}


.select-all-on-touch {
  -webkit-user-select: all;
  user-select: all;
}

.dropdown-container {
  margin-top: 1rem;
  padding-bottom: 5px;
  margin: 0px;
  height: 1.35rem;
}

.gray {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-heading {
  font-size: 12px;
  top: -8px;
  margin: 0;
  margin-bottom: 2rem;
}